import React, { useState, useMemo, useEffect } from 'react';
import { Formik } from 'formik';
import { Box } from 'theme-ui';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery, useMutation } from '@apollo/client';

import client from 'lib/client';
import scrollToElement from 'lib/scrollToElement';
import { hasExistingEvent, trackEvent } from 'lib/GA';

import MapSearch from 'components/Map/Search';
import Loader from 'components/common/Loader';
import Banner from 'components/common/Banner';
import FallbackForm from 'components/Forms/FallbackForm';
import ThrottleAlert from 'components/common/ThrottleAlert';
import * as Yup from 'yup';

import SelectAppointment from './SelectAppointment';
import CovidScreening from './CovidScreening';
import PatientDetails from './PatientDetails';
import Review from './Review';
import Success from './Success';
import ProgressBar from './ProgressBar';

import {
  LocationDetailsQuery,
  ReasonMappingQuery,
  CreateAndConfirmBookingMutation,
} from 'queries/centres';

import OffsetContainer from 'components/common/OffsetContainer';
import { Flex } from 'rebass';
import useEmergencyAppointmentMode from 'hooks/useEmergencyAppointmentMode';
import useQueryParams from 'hooks/useQueryParams';
import useSpecialistServiceMode from '../../../hooks/useSpecialistServiceMode';

const now = DateTime.local();
const threeDays = now.plus({ days: 3 });

const BookingForm = () => {
  const location = useLocation();
  const { practiceID } = queryString.parse(location.search);
  const isEmergencyAppointmentMode = useEmergencyAppointmentMode();
  const [centre, setCentre] = useState();
  const [searchResults, setSearchResults] = useState(null);
  const [email, setEmail] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [date, setDate] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [submitError, setError] = useState();
  const [form, setForm] = useState({
    patientType: 'existing',
    service: '',
    doctor: '',
    covid1: '',
    covid2: '',
    covid3: '',
    appointmentFor: '',
    title: 'Mr',
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    dob: '',
    relationship: 'Parent',
    otherRelationship: '',
    patientTitle: 'Mr',
    patientFirstName: '',
    patientLastName: '',
    recaptcha: '',
  });
  const [recaptchaWidget, setRecaptchaWidget] = useState();

  const { queryParams, setQueryParams } = useQueryParams();
  const { searchRadius } = queryParams;

  useEffect(() => {
    if (practiceID) {
      setCurrentStep(1);
    }
  }, [practiceID]);

  const { data: reasonData, loading: reasonLoading } = useQuery(
    ReasonMappingQuery,
    {
      client,
    }
  );

  const radius = useMemo(() => {
    const { postcode, suburb } = searchResults?.results || {};
    if (reasonLoading || !searchResults) {
      return undefined;
    }
    const customSearchRadius = reasonData?.settings?.radius || [];

    const byPostcode = v => v.postcode === postcode;
    const bySuburb = v => v.suburb?.toLowerCase() === suburb?.toLowerCase();

    return (
      Number(searchRadius) ||
      (
        customSearchRadius.find(byPostcode) ||
        (!postcode && customSearchRadius.find(bySuburb))
      )?.radius * 1000 ||
      null
    );
  }, [reasonData, reasonLoading, searchRadius, searchResults]);

  const reasonMapping = useMemo(() => {
    if (!reasonData) {
      return undefined;
    }

    const { reasonMapping } = reasonData;

    const filterReasons = reasons =>
      isEmergencyAppointmentMode
        ? reasons.filter(reason => reason.showForEmergencyAppointments)
        : reasons;

    return {
      new: filterReasons(reasonMapping.new),
      existing: filterReasons(reasonMapping.existing),
      newSpecialist: filterReasons(reasonMapping.newSpecialist),
      existingSpecialist: filterReasons(reasonMapping.existingSpecialist),
    };
  }, [reasonData, isEmergencyAppointmentMode]);

  const {
    specialist,
    isValidSpecialist,
    specialistSearchMode,
    specialistServices,
    asServiceOption,
    onChangeSpecialist,
  } = useSpecialistServiceMode({ reasonMapping });

  const {
    data,
    loading,
    error: locationsDetailsError,
  } = useQuery(LocationDetailsQuery, {
    skip: !practiceID && !centre?.practiceID,
    client,
    variables: {
      input: {
        practiceId: parseInt(practiceID || centre?.practiceID, 10),
      },
      nextAppointment: {
        start: now.toISODate(),
        end: threeDays.toISODate(),
      },
    },
  });

  if (practiceID && data?.locationDetails?.centre && !centre) {
    setCentre({
      ...data.locationDetails.centre,
      practiceID,
    });
  }

  // send event that form has loaded
  useEffect(() => {
    if (centre && !hasExistingEvent('formStatus', 'formViewed')) {
      trackEvent(
        {
          event: 'form',
          formName: 'bookingWidget',
          formStatus: 'formViewed',
          centerName: `NIB ${centre?.name}`,
        },
        { specialist: isValidSpecialist ? `conversion-${specialist}` : '' }
      );
    }
  }, [centre, isValidSpecialist, specialist]);

  const [createBooking] = useMutation(CreateAndConfirmBookingMutation, {
    client,
  });

  const fallbackForm = !loading && !data?.locationDetails?.centre?.enabled;
  const covidScreening =
    !loading && data?.locationDetails?.centre?.covidScreening;

  const setStep = (step, values) => {
    let valid = false;
    const fromStep = currentStep;

    if (values) {
      setForm(values);
    }

    if (step === 0 || step === 1 || step === 5) {
      setCurrentStep(step);
      scrollToElement(undefined, -100);
      valid = true;
    }

    if (step >= 1 && centre) {
      if (step === 1) {
        setCurrentStep(step);
        scrollToElement(undefined, -100);
        valid = true;
      }

      if ([2, 3, 4].includes(step) && selectedSlot) {
        setCurrentStep(step);
        scrollToElement(undefined, -100);
        valid = true;
      }
    }

    // only send when going up a step and not back
    if (valid && step > fromStep) {
      const stepLabels = {
        0: 'select a centre',
        1: 'select appointment',
        2: 'covid screening',
        3: 'patient details',
        4: 'review & confirm',
        5: 'success',
      };

      if (!hasExistingEvent('formStep', stepLabels[fromStep])) {
        trackEvent(
          {
            event: 'form',
            formName: 'bookingWidget',
            formStatus: 'steps',
            formStep: stepLabels[fromStep],
            centerName: `NIB ${centre?.name}`,
          },
          { specialist: isValidSpecialist ? `conversion-${specialist}` : '' }
        );
      }
    }
  };

  const onSubmit = async (values, { resetForm, setFieldValue, setTouched }) => {
    setError();

    const input = {
      bookingDetailsForm: {
        slotId: selectedSlot.slotId,
        title: (values.title || '').toUpperCase(),
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        dob: values.dob,
        mobile: values.contactNumber,
      },
      emailDetails: {
        practiceId: centre.practiceID,
        service:
          reasonMapping?.[values.patientType]?.find(
            r => r.value === values.service
          )?.title || values.service,
        patientType: (values.patientType || '').toUpperCase() || null,
        patientFirstName: values.patientFirstName || null,
        patientLastName: values.patientLastName || null,
        patientRelation: (values.relationship || '').toUpperCase() || null,
        otherRelationship: values.otherRelationship || null,
        appointmentUserType: {
          Myself: 'MYSELF',
          'Someone else': 'SOMEONE_ELSE',
        }[values.appointmentFor],
        agreed: true,
        bookingResponses: covidScreening
          ? [
              {
                question: 'Have you been diagnosed as having COVID-19?',
                answer: values.covid1,
              },
              {
                question: 'Are you currently required to be in self-isolation?',
                answer: values.covid2,
              },
              {
                question: 'Do you have any flu like symptoms?',
                answer: values.covid3,
              },
            ]
          : [],
      },
    };

    // set email here as formik clears values on submit
    setEmail(values.email);

    try {
      trackEvent(
        {
          event: 'bookingConfirmation',
          enquiryType: 'bookingWidget',
          appointmentStatus: 'confirmed',
          centerName: `NIB ${centre?.name}`,
          patientType: (values.patientType?.split(' ')[0] || '').toUpperCase(),
          service: values?.service,
          practitionerName: data?.locationDetails?.doctors?.find(
            d => d.doctorId === values?.doctor.toString()
          )?.doctorName,
          appointmentDate:
            selectedSlot?.start &&
            moment(selectedSlot.start)
              .tz(selectedSlot.timezone)
              .format('DD-MM-YYYY'),
          appointmentTime: moment(selectedSlot.start)
            .tz(selectedSlot.timezone)
            .format('h:mma'),
          dateOfBooking: moment().format('DD-MM-YYYY'),
        },
        {
          specialist: isValidSpecialist ? `book & confirmed-${specialist}` : '',
        }
      );

      trackEvent(
        {
          event: 'form',
          formName: 'bookingWidget',
          formStatus: 'steps',
          formStep: 'success',
          centerName: `NIB ${centre?.name}`,
        },
        {
          specialist: isValidSpecialist ? `book & confirmed-${specialist}` : '',
        }
      );
    } catch (e) {
      console.error(e);
    }

    try {
      const result = await createBooking({
        variables: {
          validation: {
            token: values.recaptcha,
          },
          input,
        },
      });

      if (result?.data?.createAndConfirmBooking?.success) {
        resetForm();
        setStep(5);
      }
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      // Reset ReCAPTCHA component to allow for retry if submit function fails
      setFieldValue('recaptcha', '');
      window.grecaptcha?.reset(recaptchaWidget);
      setTouched({}, false);
    }

    return true;
  };

  const detailsSteps = useMemo(
    () => [
      { label: 'Select a centre', value: 0, enabled: !practiceID },
      { label: 'Select appointment', value: 1, enabled: true },
      { label: 'COVID screening', value: 2, enabled: covidScreening },
      {
        label: 'Patient details',
        value: 3,
        enabled: true,
      },
      {
        label: 'Review & confirm',
        value: 4,
        enabled: true,
      },
    ],
    [covidScreening, practiceID]
  );

  const selectCentre = selectedCentre => {
    setCentre(selectedCentre);
    setForm({ ...form, service: specialist, doctor: '' });
    setStep(1);
  };

  if (locationsDetailsError) {
    return (
      <Flex
        sx={{
          height: '100vh',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <ThrottleAlert error={locationsDetailsError} />
      </Flex>
    );
  }

  if (currentStep === 0) {
    return (
      <MapSearch
        setCentre={selectCentre}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        setQueryParams={setQueryParams}
        isValidSpecialist={isValidSpecialist}
        specialist={specialist}
        specialistSearchMode={specialistSearchMode}
        searchRadius={radius}
        specialistServices={specialistServices}
        onChangeSpecialist={onChangeSpecialist}
      />
    );
  }

  if (loading || reasonLoading) {
    return <Loader fullScreen />;
  }

  if (fallbackForm) {
    return (
      <OffsetContainer>
        <FallbackForm />
      </OffsetContainer>
    );
  }

  return (
    <>
      <Banner centre={centre} />
      <OffsetContainer
        sidebar={
          currentStep !== 5 && (
            <ProgressBar
              data={{
                title: 'Book an appointment',
                titleColor: 'rgb(67, 67, 67)',
                align: 'center',
                backgroundColor: 'rgb(20, 74, 56)',
                steps: detailsSteps,
              }}
              currentStep={currentStep}
              setStep={setStep}
            />
          )
        }
      >
        <Formik
          initialValues={form}
          validate={values => validate(values, currentStep)}
          onSubmit={onSubmit}
          validationSchema={BookingForm.formValidationSchema}
        >
          {({ handleSubmit }) => {
            return (
              <Box
                as="form"
                onSubmit={handleSubmit}
                sx={{
                  maxWidth: '694px',
                  width: '100%',
                  margin: '0 auto',
                }}
              >
                {{
                  1: (
                    <SelectAppointment
                      locationDetails={data?.locationDetails}
                      reasons={reasonMapping}
                      setStep={setStep}
                      date={date}
                      setDate={setDate}
                      selectedSlot={selectedSlot}
                      setSelectedSlot={setSelectedSlot}
                      selectedCentre={centre}
                      nextStep={covidScreening ? 2 : 3}
                      specialistSearchMode={specialistSearchMode}
                      isValidSpecialist={isValidSpecialist}
                      specialist={specialist}
                      asServiceOption={asServiceOption}
                      onChangeSpecialist={onChangeSpecialist}
                    />
                  ),
                  2: <CovidScreening setStep={setStep} />,
                  3: <PatientDetails setStep={setStep} />,
                  4: (
                    <Review
                      setStep={setStep}
                      selectedSlot={selectedSlot}
                      submitError={submitError}
                      selectedCentre={centre}
                      reasons={reasonMapping}
                      steps={detailsSteps}
                      setRecaptchaWidget={setRecaptchaWidget}
                    />
                  ),
                  5: <Success selectedCentre={centre} email={email} />,
                }[currentStep] || <div />}
              </Box>
            );
          }}
        </Formik>
      </OffsetContainer>
    </>
  );
};

const validate = (values, currentStep) => {
  const errors = {};
  if (!values.patientType) {
    errors.patientType = 'Patient type is required.';
  }
  if (!values.service) {
    errors.service = 'Service is required.';
  }
  if (!values.appointmentFor) {
    errors.appointmentFor = 'This field is required.';
  } else {
    if (!values.title) {
      errors.title = 'Title is required.';
    }
    if (!values.firstName) {
      errors.firstName = 'First name is required.';
    }
    if (!values.lastName) {
      errors.lastName = 'Last name is required.';
    }
    if (!values.contactNumber) {
      errors.contactNumber = 'Contact number is required.';
    }
    if (!values.email) {
      errors.email = 'Email is required.';
    }
    if (!values.dob) {
      errors.dob = 'Date of birth is required.';
    }
    if (!moment(values.dob).isValid()) {
      errors.dob =
        'Invalid date. Please enter a date in the format dd/mm/yyyy.';
    }
    if (values.appointmentFor === 'Someone else') {
      if (!values.relationship) {
        errors.relationship = 'This field is required';
      } else if (values.relationship === 'Other' && !values.otherRelationship) {
        errors.otherRelationship = 'This field is required.';
      }
      if (!values.patientTitle) {
        errors.patientTitle = 'Title is required';
      }
      if (!values.patientFirstName) {
        errors.patientFirstName = 'First name is required.';
      }
      if (!values.patientLastName) {
        errors.patientLastName = 'Last name is required.';
      }
    }
    if (currentStep === 4 && !values.recaptcha) {
      errors.recaptcha = 'Recaptcha is required.';
    }
  }

  return errors;
};

BookingForm.formValidationSchema = Yup.object().shape({
  patientType: Yup.string().oneOf(['existing', 'new']),
  service: Yup.string().required('Please select a service'),
  doctor: Yup.string().required('Please select a doctor'),
  covid1: Yup.string().oneOf(['Yes', 'No']),
  covid2: Yup.string().oneOf(['Yes', 'No']),
  covid3: Yup.string().oneOf(['Yes', 'No']),
  covid4: Yup.string().oneOf(['Yes', 'No']),
  appointmentFor: Yup.string().oneOf(['Myself', 'Someone else']),
  title: Yup.string().required('Please enter your title'),
  firstName: Yup.string()
    .matches(
      /^[ A-Za-z0-9-']*$/,
      'special characters are not allowed for this field'
    )
    .required('Required'),
  lastName: Yup.string()
    .matches(
      /^[ A-Za-z0-9-']*$/,
      'special characters are not allowed for this field'
    )
    .required('Required'),
  contactNumber: Yup.string()
    .min(10, 'Please enter a valid 10 digit AU mobile number')
    .matches(
      /^04\d{8}$/,
      'Please enter a valid AU mobile number starting with 04'
    )
    .required('Required'),
  email: Yup.string().email('invalid email address').required('Required'),
  dob: Yup.date()
    .transform((_, rawValue) => {
      // transform to date object to fix dates < 100
      return moment(rawValue, ['yyyy-mm-dd']).toDate();
    })
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      'Date of birth must be after 1900'
    )
    .max(new Date(), 'Date is in the future')
    .required('Required'),
  relationship: Yup.string(),
  otherRelationship: Yup.string(),
  patientTitle: Yup.string(),
  patientFirstName: Yup.string(),
  patientLastName: Yup.string(),
  recaptcha: Yup.string(),
});

export default BookingForm;
